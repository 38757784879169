import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "custom-page",
    data() {
        return {
            valid: false,
            isSaving: false,
            oobleeBannerDefinition: {
                text: "",
                linkValue: "",
                bannerType: "",
                linkType: "",
                base64ImageContentType: "",
                base64Image: "",
                shopName: "",
                shopId: ""
            },
            bannerDefinitionId: null,
            tabIndex: 0,
            shops: [],
            search: "",
            types: ["TOP","MAIN_PAGE_DEFAULT"],
            linkTypes: ["EXTERNAL_LINK", "SHOP"],
            shopsIsLoading: false,
            requiredRule: [v => !!v || "Field is required"]
        };
    },
    created() {
        this.bannerDefinitionId = this.$route.params.bannerDefinitionId;
        let title =
            this.bannerDefinitionId != null
                ? "Banner definition details"
                : "Insert new banner definition";
        this.$store.dispatch(SET_BREADCRUMB, [{title: title}]);
        if (this.bannerDefinitionId != null) {
            ApiService.get(`api/banner-definition`, `${this.bannerDefinitionId}`)
                .then(response => {
                    this.$log.debug("Banner definition: ", response.data);
                    this.oobleeBannerDefinition = response.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        }
        this.searchShops();
    },
    methods: {
        previousState() {
            this.$router.go(-1);
        },
        endsWith(suffix, str) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        },
        paddingSize(value) {
            if (this.endsWith("==", value)) {
                return 2;
            }
            if (this.endsWith("=", value)) {
                return 1;
            }
            return 0;
        },
        size(value) {
            return (value.length / 4) * 3 - this.paddingSize(value);
        },
        byteSize(base64String) {
            return this.formatAsBytes(this.size(base64String));
        },
        formatAsBytes(size) {
            return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
        },
        handleImage(event) {
            if (event && event.target.files && event.target.files[0]) {
                const file = event.target.files[0];
                // this.toBase64(file);
                this.toBase64(file, base64Data => {
                    this.oobleeBannerDefinition.base64Image = base64Data;
                });
            }
        },
        toBase64(file, cb) {
            const fileReader = new FileReader();
            fileReader.onload = e => {
                const base64Data = e.target.result;
                cb(base64Data);
            };
            fileReader.readAsDataURL(file);
        },
        searchShops() {

            // Items have already been loaded
            if (this.shops.length > 0) return;

            // Items have already been requested
            if (this.shopsIsLoading) return;

            this.shopsIsLoading = true;
            ApiService.query("api/shops/dropdown-list")
                .then(res => {
                    this.shops = res.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.shopsIsLoading = false));
        },
        /**
         * Set current active on click
         * @param event
         */
        setActiveTab(event) {
            let target = event.target;
            if (!event.target.classList.contains("navi-link")) {
                target = event.target.closest(".navi-link");
            }

            const tab = target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".navi-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }

            // set clicked tab index to bootstrap tab
            this.tabIndex = parseInt(target.getAttribute("data-tab"));

            // set current active tab
            target.classList.add("active");
        },
        validateAndSave() {
            if (this.$refs.form.validate()) {
                this.saveBannerDefinition(this.oobleeBannerDefinition);
            }
        },
        saveBannerDefinition(selectedBannerDefinition) {
            this.isSaving = true;
            this.loading = true;
            if (selectedBannerDefinition.bannerType === 'TOP') {
                selectedBannerDefinition.shopId = null;
                selectedBannerDefinition.shopName = null;
                selectedBannerDefinition.linkType = null;
                selectedBannerDefinition.linkValue = null;

            }
            if (selectedBannerDefinition.id != null) {
                ApiService.put(`api/banner-definition`, selectedBannerDefinition)
                    .then(response => {
                        this.$log.debug("Banner definition created: " + response);
                        this.$emit("bannerDefinitionSaved", "bannerDefinitionSaved");
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState();
                    });
            } else {
                ApiService.post(`api/banner-definition`, selectedBannerDefinition)
                    .then(response => {
                        this.$log.debug("Banner definition created: " + response);
                        this.$emit("bannerDefinitionSaved", "bannerDefinitionSaved");
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState();
                    });
            }
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
